/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// import logo from "../assets/ic_round-dashboard.svg";
import menuBar from "../assets/menuBar.svg";

export function NestedSidebarItem({
  icon,
  name,
  onTap,
  className,
  children,
  path,
  set_path,
}: any) {
  const [open, set_open] = useState(false);
  return (
    <div className={className + " nested-sidebar-item "}>
      <div
        className={
          "sidebar-item items-center gap-6 p-3 my-3 rounded-lg cursor-pointer hover:bg-[#6F6AF8] bg-opacity-10 text-[#2B3674] font text-sm flex w-100 "
        }
        onClick={() => {
          set_open(!open);
          // onTap();
        }}
      >
        {icon && (
          <div className="icon">
            <img className="w-3 h-3" src={icon} alt="" />{" "}
          </div>
        )}
        <div className="name">{name}</div>
        <i className={"fa-solid fa-angle-up " + (open ? "" : "rotate-180")}></i>
      </div>

      <div className="pl-10">
        {open
          ? children.map((c: any, i: any) => {
              return React.cloneElement(c, { key: i, path, set_path });
            })
          : null}
      </div>
    </div>
  );
}

export function SidebarItem({
  icon,
  name,
  onTap,
  className,
  to,
  path,
  set_path,
  Link,
  menu,
  disableCondition,
}: any) {
  return (
    <Link to={to} className={className}>
      <button
        className="w-full text-center"
        disabled={disableCondition}
        onClick={() => {
          set_path(to);
          if (onTap) onTap();
        }}
      >
        {icon && icon}
        {name && <h4 className="font-bold mt-1 text-[9px]">{name}</h4>}
      </button>
    </Link>
  );
}

function SideBar({ children, schoolName, Link, path, set_path }: any) {
  return (
    <div
      className={
        "sidebar-container font-Poppins transition-all duration-150 z-50  flex fixed  w-[64px]"
      }
    >
      <div className={`bg-[#1B163B] z-40 flex flex-col w-full min-h-screen`}>
        <div
          className={
            "flex justify-center mx-auto mt-2   h-[60px] w-[60px] items-center p-4 mb-[50px] "
          }
        >
          <img className="h-full w-full object-cover" src={menuBar} alt="" />
        </div>
        <div className="sidebar-items gap-y-2 flex flex-col h-full">
          {children.length &&
            children?.map((c: any, i: any) => {
              return React.cloneElement(c, {
                key: i,
                path,
                set_path,
                Link,
              });
            })}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
