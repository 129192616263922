import React, { useEffect, useState } from "react";
import { GET_KYC_DOCUMENTS, VALIDATE_USER } from "../../Qurries";
import { useQuery } from "@apollo/client";
import { Link, NavLink, Navigate, Route, Routes } from "react-router-dom";
import SideBar, { SidebarItem } from "../../components/SideBar";
import { IoDocumentTextOutline } from "react-icons/io5";
import Navbar from "../../components/Navbar";
import InstituteInformation from "../Components/InstituteInformation/InstituteInformation";
import KycForm from "../Components/KycUploadation/KycForm";
import { ReactComponent as UpLoad } from "../../assets/kycDocumentationLogo.svg";
import { IoCloudUpload } from "react-icons/io5";

function NewDashboard() {
  const [menu, setMenu] = useState(false);
  const { data, loading, error } = useQuery(VALIDATE_USER, {
    variables: {
      token: localStorage.getItem("userDetails"),
    },
  });
  const { data: kyc } = useQuery(GET_KYC_DOCUMENTS);

  const [legalName, setLegalName] = useState(kyc?.getKycDocuments?.legalName);
  const [businessCategory, setBusinessCategory] = useState(
    kyc?.getKycDocuments?.businessCategory || "Individual"
  );
  useEffect(() => {
    setLegalName(kyc?.getKycDocuments?.legalName);
    setBusinessCategory(kyc?.getKycDocuments?.businessCategory);
  }, [kyc?.getKycDocuments?.legalName, kyc?.getKycDocuments?.businessCategory]);
  const [path, set_path] = useState(window.location.pathname);

  if (error) {
    localStorage.removeItem("userDetails");
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <div className="">
        <SideBar
          schoolName={"Edviron"}
          Link={NavLink}
          path={path}
          set_path={set_path}
        >
          <SidebarItem
            icon={<UpLoad />}
            name=""
            to="/"
            className={({ isActive }: any) =>
              isActive || window.location.pathname === "/upload"
                ? " flex-col sidebar-item overflow-hidden flex text-center items-center p-3 my-2  cursor-pointer  font-bold text-[9px] rounded-l-lg ml-2 bg-[#EDF1F4] text-opacity-75 text-[#593DF7BF]"
                : " text-[#A3AED0] flex text-center items-center p-3"
            }
          />

          <SidebarItem
            icon={<IoCloudUpload className={"text-[25px]"} />}
            name="upload"
            to="/upload"
            className="hidden"
          />
        </SideBar>
        <div className="bg-[#EDF1F4]  flex flex-col min-h-screen pl-[91px] pr-6">
          <Navbar userInfo={data?.validateUser?.school_name} />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <InstituteInformation
                    legalName={legalName}
                    userDetails={data?.validateUser}
                    setLegalName={setLegalName}
                    kyc={kyc}
                    businessCategory={businessCategory}
                    setBusinessCategory={setBusinessCategory}
                  />
                </>
              }
            />
            <Route
              path="/upload"
              element={
                <>
                  <KycForm
                    userDetails={data?.validateUser}
                    legalName={legalName}
                  />
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default NewDashboard;
