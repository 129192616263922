import React, { useContext, useState } from "react";
import logo from "../assets/edvironLogo.svg";

import { GoEye } from "react-icons/go";
import { PiSignOutBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

function Navbar({ userInfo }: any) {
  const [userMenu, setUserMenu] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center py-4 pb-2">
      <img src={logo} className="h-10" alt="logo" />
      <div className="flex items-center gap-x-2 relative">
        <div className="flex h-8 w-8 justify-center shrink-0 items-center bg-[#7E6AF633] bg-opacity-20 rounded-full">
          {userInfo?.slice(0, 1)}
        </div>
        <p
          onClick={() => {
            setUserMenu(!userMenu);
          }}
          className="max-w-[100px] cursor-pointer text-xs text-[#1B163B] font-normal truncate"
        >
          {userInfo && userInfo}
        </p>
        <svg
          onClick={() => {
            setUserMenu(!userMenu);
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className="text-[#717171] w-4 cursor-pointer"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
        {userMenu && (
          <div className="z-50 text-xs absolute top-10 right-0 bg-[#F6F8FA] py-4 w-[9rem]  flex flex-col justify-center gap-y-4 shadow-lg rounded-md mt-2">
            <div className="w-full flex flex-col px-4">
              <div className="flex text-[#A9B2CF] font-normal border-b-2 pb-3 items-center gap-x-3">
                <GoEye />
                <p>View Profile</p>
              </div>
              <div
                onClick={() => {
                  localStorage.removeItem("userDetails");
                  localStorage.removeItem("FileNames");

                  const url_token = localStorage.getItem("urlToken");
                  if (!url_token) {
                    navigate(`/login`);
                    //window.location.reload();
                  } else {
                    navigate(`/verify-kyc?token=${url_token}`);
                    //window.location.reload();
                  }
                }}
                className="flex cursor-pointer text-[#1B163B] font-normal pt-3 items-center gap-x-3"
              >
                <PiSignOutBold />
                <p>Sign out</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
