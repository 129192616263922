import TimeLine from "../../../components/TimeLine";
import uploadLogo from "../../../assets/uploadLogo.svg";

import "./KycForm.css";
import { useContext, useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import {
  UPLOAD_KYC_DOCUMENT,
  GET_KYC_DOCUMENTS,
  DELETE_KYC_DOCUMENT,
  SUBMIT_KYC,
  VALIDATE_USER,
} from "../../../Qurries";
import { useMutation, useQuery } from "@apollo/client";
import { NavLink, Navigate } from "react-router-dom";
import { dashboardContext } from "../../Dashboard/Dashboard";
import DocumentUploaded from "../../../components/DocumentUploaded";
import IframeModal from "../../../components/IframeModal";
import { hasNullValue } from "../InstituteInformation/InstituteInformation";
import ConfirmationBox from "../../../components/ConfermationBox";
import Modal from "../../../components/Modal/Modal";
import UploadDoc from "../../../components/UploadDoc/UploadDoc";
import { toast } from "react-toastify";
import { Document, pdfjs } from "react-pdf";
import { documentData } from "../data";
import TimeLineHorizontal from "../../../components/TimeLineHorizontal";

import DeclarationBox from "./components/DeclarationBox";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function KycForm({ userDetails, legalName }: any) {
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [isBackDrop, setIsBackDrop] = useState(false);
  const entityPanRef = useRef<HTMLInputElement>(null);
  const businessProofRef = useRef<HTMLInputElement>(null);
  const bankProofRef = useRef<HTMLInputElement>(null);
  const affiliationCertificateRef = useRef<HTMLInputElement>(null);
  const authorisedSignatoryPANRef = useRef<HTMLInputElement>(null);
  const authorisedsignatoryAadhaarRef = useRef<HTMLInputElement>(null);
  const [addharCard, setAddharCard] = useState<any>(null);
  const [businessProof, setBusinessProof] = useState<any>(null);
  const [bankProof, setBankProof] = useState<any>(null);
  const [affiliationCertificate, setaffiliationCertificate] =
    useState<any>(null);
  const [authorisedSignatoryPAN, setauthorisedSignatoryPAN] =
    useState<any>(null);
  const [entityPan, setentityPan] = useState<any>(null);
  const [fileNameData, setfilesNameData] = useState<any>(null);
  const [filesUploaded, setFilesUploaded] = useState<boolean>(false);
  const [entityLoader, setEntityLoader] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [confermation, setConfermation] = useState(false);
  const [submitConfermation, setSubmitConfermation] = useState(false);
  const [extraDeclarationDoc, setExtraDeclarationDoc] = useState<any>(null);

  const [extraDocsRequirement, setExtraDocsRequirement] = useState<
    boolean | null
  >(null);
  const [extraFilesUploaded, setExtraFilesUploaded] = useState<boolean>(false);
  const [websiteLink, setWebsiteLink] = useState<string | null>(null);
  const { data, loading, refetch } = useQuery(GET_KYC_DOCUMENTS);

  // const { data: validUser } = useQuery(VALIDATE_USER, {
  //   variables: {
  //     token: information,
  //   },
  // });

  const [DELETE_DOCS] = useMutation(DELETE_KYC_DOCUMENT, {
    refetchQueries: [{ query: GET_KYC_DOCUMENTS }],
    errorPolicy: "ignore",
  });
  const [submit_kyc_final] = useMutation(SUBMIT_KYC, {
    refetchQueries: [{ query: GET_KYC_DOCUMENTS }],
    errorPolicy: "ignore",
  });

  // const { isSubmitted, setIsSubmitted } = useContext<any>(dashboardContext);

  useEffect(() => {
    if (data?.getKycDocuments) {
      if (data?.getKycDocuments?.isSubmitted === true) {
        setFilesUploaded(true);
        if (data?.getKycDocuments?.declarationDoc) {
          setExtraFilesUploaded(true);
        }
      }
      if (data?.getKycDocuments?.websiteLink) {
        setWebsiteLink(data?.getKycDocuments?.websiteLink);
      }

      if (!data?.getKycDocuments?.hasDeclaration) {
        setExtraDocsRequirement(true);
      }
      if (
        data?.getKycDocuments?.isSubmitted === true &&
        data?.getKycDocuments?.hasDeclaration
      ) {
        setExtraDocsRequirement(false);
      }
      setBankProof(data?.getKycDocuments?.bankProof);
      setBusinessProof(data?.getKycDocuments?.businessProof);
      setentityPan(data?.getKycDocuments?.entityPan);
      setAddharCard(data?.getKycDocuments?.aadhar);
      setaffiliationCertificate(data?.getKycDocuments?.affiliation);
      setauthorisedSignatoryPAN(data?.getKycDocuments?.authPan);
      setExtraDeclarationDoc(data?.getKycDocuments?.declarationDoc);
    }
  }, [data]);
  useEffect(() => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (websiteLink && urlRegex.test(websiteLink)) {
      setExtraFilesUploaded(true);
    } else {
      setExtraFilesUploaded(false);
    }
  }, [websiteLink]);

  const [uploadKycDocument] = useMutation(UPLOAD_KYC_DOCUMENT, {
    refetchQueries: [{ query: GET_KYC_DOCUMENTS }],
    errorPolicy: "ignore",
  });

  const handleDeleteDocs = async (typeName: any) => {
    const res = await DELETE_DOCS({
      variables: {
        typeName: typeName,
      },
    });
    if (res?.data?.deleteKycDocument) {
      toast.success("Document(s) deleted successfully");
    }
  };
  useEffect(() => {
    const jsonData = localStorage.getItem("FileNames");
    if (jsonData) {
      const filesData = JSON.parse(jsonData);
      setfilesNameData(filesData);
    }
  }, []);

  function handleFileSelect(event: any, type: string) {
    var selectedFile = event;

    const dataForm: any = {};

    if (selectedFile) {
      const jsonData: any = localStorage.getItem("FileNames");
      if (jsonData) {
        const data = JSON.parse(jsonData);
        data[type] = selectedFile.name;
        localStorage.setItem("FileNames", JSON.stringify(data));
        setfilesNameData(data);
      } else {
        dataForm[type] = selectedFile.name;
        localStorage.setItem("FileNames", JSON.stringify(dataForm));
        setfilesNameData(dataForm);
      }
      var reader = new FileReader();
      reader.onload = function (event) {
        var selectedFile: any = selectedFile;
        // Convert the file to Base64
        if (typeof event.target?.result === "string") {
          var selectedFileBase64 = event.target.result.split(",")[1];
          onSubmit(selectedFileBase64, type);
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  }
  // const onSubmit = async (e: any, type: string) => {
  //   setUploadingDocument(true);
  //   try {
  //     const data = await uploadKycDocument({
  //       variables: {
  //         file: e,
  //         typeName: type,
  //       },
  //     });
  //     if (data?.data?.uploadKycDocument) {
  //       setUploadingDocument(false);
  //     }
  //   } catch (err) {
  //     setUploadingDocument(false);
  //   }
  // };
  const onSubmit = async (e: any, type: string) => {
    setUploadingDocument(true);
    try {
      const data = await uploadKycDocument({
        variables: {
          file: e,
          typeName: type,
        },
      });
      if (!data?.data) {
        setUploadingDocument(false);
        clearDocs(type);
        // toast.error("Error in uploading document, Please check the file");
      }
      if (data?.data?.uploadKycDocument) {
        setUploadingDocument(false);
      }
    } catch (err) {
      setUploadingDocument(false);
    }
  };
  function showPdfHandler(pdf: any) {
    if (pdf) {
      setPdfFile(pdf);
      // setShowPdfFile(true);
    } else {
      setPdfFile(null);
      // setShowPdfFile(false);
    }
  }
  const formSubmitHandler = async () => {
    await submit_kyc_final({
      variables: {
        hasDeclaration: !extraDocsRequirement,
        webSiteLink: websiteLink,
      },
    });
    setIsBackDrop(true);
    refetch();
  };

  if (data?.getKycDocuments) {
    if (!(legalName || data?.getKycDocuments?.legalName)) {
      return <Navigate to="/" />;
    }
  }
  const clearDocs = async (type: any) => {
    if (type === "entityPan") {
      setentityPan(null);
    } else if (type === "businessProof") {
      setBusinessProof(null);
    } else if (type === "bankProof") {
      setBankProof(null);
    } else if (type === "affiliation") {
      setaffiliationCertificate(null);
    } else if (type === "authPan") {
      setauthorisedSignatoryPAN(null);
    } else if (type === "aadhar") {
      setAddharCard(null);
    } else if (type === "declarationDoc") {
      setExtraDeclarationDoc(null);
    } else if (type === "all") {
      setentityPan(null);
    }
  };

  if (loading) return <div>loading....</div>;

  const isPDFCorrupted = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target && event.target.result) {
          try {
            const blob = new Blob([event.target.result], {
              type: "application/pdf",
            });
            const url = URL.createObjectURL(blob);
            const pdfDocument = await pdfjs.getDocument({ url }).promise;
            resolve(false);
          } catch (error) {
            resolve(true);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };
  return (
    <div className=" bg-[#EDF1F4]">
      <IframeModal url={pdfUrl} isOpen={isModalOpen} onClose={setIsModalOpen} />
      <Modal setOpen={setConfermation} open={confermation}>
        <ConfirmationBox
          setOpen={setConfermation}
          funtion={() => handleDeleteDocs("Clear")}
          confirmationText={"delete all documents"}
          ButtonText={"Delete"}
        />
      </Modal>

      <Modal setOpen={setSubmitConfermation} open={submitConfermation}>
        <ConfirmationBox
          setOpen={setSubmitConfermation}
          funtion={() => formSubmitHandler()}
          confirmationText={"Submit"}
          ButtonText={"Submit"}
          ButtonBackground="bg-[#6687FF]"
          CborderColor=" border-[#B8C0CD]"
        />
      </Modal>

      {isBackDrop && <DocumentUploaded setIsBackDrop={setIsBackDrop} />}
      {data?.getKycDocuments?.isSubmitted === true && (
        <p className="text-[#00AA11] text-sm">
          Your documents have been successfully uploaded. We will get back to
          you shortly after verification.
        </p>
      )}
      <div className="min-h-[34rem] mt-4 bg-[#F6F8FA] rounded-lg md:px-8 px-3 py-6 relative w-auto overflow-hidden  ">
        <div className="mb-4 flex flex-col">
          <div className="w-full">
            <h1 className="right-4 text-[#6687FF] font-poppins font-semibold text-lg">
              Complete Your KYC
            </h1>
            <div className="h-10 w-0  ml-4 border border-solid border-slate-500 bg-slate-300"></div>
            <div className="relative h-0 ml-4 w-4 mb-[39px] border border-solid border-slate-500 bg-slate-300">
              <div className="absolute right-[-2px] top-[-4px] w-2 h-2 border-solid border-t-2 border-r-2 border-slate-500 transform rotate-45"></div>
              <h2 className="absolute top-[-10.5px] left-8 w-max text-[14px] font-medium">
                Upload Your Document
              </h2>
            </div>
          </div>
          <div className=" lg:hidden block w-full mt-4 mb-8">
            <TimeLineHorizontal
              type={"KycForm"}
              infoStatus={hasNullValue(userDetails)}
              documentStatus={hasNullValue(data?.getKycDocuments)}
              isSubmitted={data?.getKycDocuments.isSubmitted}
            />
          </div>
        </div>
        <div className="rounded-lg w-full lg:mt-0 mt-8">
          <div className="w-full md:pl-11 gap-x-4 flex items-center">
            <div className="lg:w-3/4 grid-cols-2 grid w-full gap-4">
              {documentData.map((document) => {
                return <UploadDoc key={document?.title} data={document} disableOn={filesUploaded} />;
              })}
              <div className="mt-6 text-[14px] w-full col-span-2">
                <div className="w-full">
                  <div className="flex items-center">
                    {extraDocsRequirement === false && (
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 accent-[#6687FF]"
                        id="option2"
                        name="options"
                        disabled={filesUploaded && !extraDocsRequirement}
                        checked={extraDocsRequirement === false}
                        onChange={() => {
                          setExtraDocsRequirement(null);
                        }}
                      ></input>
                    )}
                    <p className=" text-sm text-[#1B163B] items-center ">
                      {extraDocsRequirement
                        ? "If both details are listed on the website, please provide the website link. Alternatively, you can upload the declaration. (Declaration Document)"
                        : "**Note: Are both the Institute name and the Business Entity name stated together on a single provided document?"}
                    </p>
                  </div>

                  {extraDocsRequirement === null && (
                    <div className="flex gap-x-4 w-full mt-2">
                      <button
                        onClick={() => {
                          setExtraDocsRequirement(true);
                        }}
                        className="px-6 py-2 text-sm text-[#717171] rounded-md  border border-[#B8C0CD]"
                      >
                        No
                      </button>

                      <button
                        onClick={() => {
                          setExtraDocsRequirement(false);
                        }}
                        className="px-6 py-2 text-sm text-white disabled:text-[#F6F8FA] rounded-md bg-[#6687FF] disabled:bg-[#B8C0CD] disabled:border border-[#B8C0CD]"
                      >
                        Yes
                      </button>
                    </div>
                  )}
                  {extraDocsRequirement && (
                    <DeclarationBox
                      setExtraFilesUploaded={setExtraFilesUploaded}
                      setExtraDocsRequirement={setExtraDocsRequirement}
                      websiteLink={websiteLink}
                      setWebsiteLink={setWebsiteLink}
                      filesUploaded={filesUploaded}
                      extraFilesUploaded={extraFilesUploaded}
                      extraDeclarationDoc={extraDeclarationDoc}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className=" lg:block hidden self-start">
              <TimeLine
                type={"KycForm"}
                infoStatus={hasNullValue(userDetails)}
                documentStatus={hasNullValue(data?.getKycDocuments)}
                isSubmitted={data?.getKycDocuments.isSubmitted}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          " flex md:flex-nowrap w-full justify-between flex-wrap items-center md:mt-8 mt-4 gap-y-4 pb-4 "
        }
      >
        <NavLink to="/">
          <button className="rounded-lg text-sm bg-[#F6F8FA] border border-gray-300 px-6 py-2">
            Back
          </button>
        </NavLink>

        <div>
          {data?.getKycDocuments?.isSubmitted === true && (
            <button
              disabled={
                !(
                  bankProof &&
                  addharCard &&
                  entityPan &&
                  authorisedSignatoryPAN &&
                  businessProof
                ) || filesUploaded
              }
              type="submit"
              onClick={(e) => {
                setSubmitConfermation(true);
              }}
              className="px-6 py-2 text-white disabled:text-[#F6F8FA] rounded-md bg-[#6687FF] disabled:bg-[#B8C0CD] disabled:border border-[#B8C0CD]"
            >
              Submit
            </button>
          )}
        </div>
        {data?.getKycDocuments?.isSubmitted === false && (
          <div
            className={
              "flex gap-x-4 md:w-auto w-full " +
              (data?.getKycDocuments?.isSubmitted === false
                ? " md:justify-start justify-between"
                : " md:justify-start justify-end")
            }
          >
            <button
              disabled={data?.getKycDocuments.isSubmitted}
              onClick={() => {
                setConfermation(true);
              }}
              className="px-6 py-2 text-sm text-[#717171] rounded-md  border border-[#B8C0CD]"
            >
              Clear
            </button>

            {data?.getKycDocuments?.isSubmitted === false && (
              <button
                disabled={
                  !(
                    bankProof &&
                    addharCard &&
                    entityPan &&
                    authorisedSignatoryPAN &&
                    businessProof
                  ) ||
                  extraDocsRequirement === null ||
                  (extraDocsRequirement === true &&
                    !extraFilesUploaded &&
                    !extraDeclarationDoc) ||
                  filesUploaded
                }
                type="submit"
                onClick={(e) => {
                  setSubmitConfermation(true);
                }}
                className="px-6 py-2 text-sm text-white disabled:text-[#F6F8FA] rounded-md bg-[#6687FF] disabled:bg-[#B8C0CD] disabled:border border-[#B8C0CD]"
              >
                Submit
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default KycForm;
