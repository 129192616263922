import React, { useEffect, useState } from "react";
import UploadDoc from "../../../../components/UploadDoc/UploadDoc";
function DeclarationBox(props: any) {
  const documentDetails = {
    title: "Declaration Document",
    description: "",
    entityName: "declarationDoc",
    sampleFileUrl: [
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFile_DeclarationDoc.docx",
    ],
  };

  const handleExtraDocsSubmission = () => {
    props.setExtraDocsRequirement(null);
    props.setExtraFilesUploaded(false);
  };

  return (
    <div className="mt-4">
      {(!props.filesUploaded || props.websiteLink) && (
        <div className="mb-3">
          <div className="relative w-[50%]">
            <label
              className={`absolute left-10 top-[-7px] bg-[#f6f8fa] px-1 text-xs font-normal text-[#1B163B] ${
                props?.filesUploaded === true
                  ? " text-[#B8C0CD]"
                  : " text-[#1B163B]"
              }`}
            >
              Website Link
            </label>
            <input
              className={
                "px-4 py-3 bg-transparent text-xs w-full border-2 rounded-md border-solid border-gray-300 outline-none text-[#717171] placeholder:text-[#B8C0CD]"
              }
              placeholder="https://example.com"
              type="url"
              disabled={props?.filesUploaded}
              defaultValue={props.websiteLink}
              onChange={(e) => {
                props.setWebsiteLink(e.target.value);
              }}
            ></input>
          </div>
        </div>
      )}
      <div>
        {!props.filesUploaded && (
          <div className="flex items-center mb-3">
            <div className="flex-grow border-2  border-gray-400 border-dashed w-[45%]"></div>
            <span className="mx-3 text-sm text-gray-500 uppercase font-bold tracking-wider">
              OR
            </span>
            <div className="flex-grow border-2  border-gray-400 border-dashed w-[45%]"></div>
          </div>
        )}
        {(!props.filesUploaded || props.extraDeclarationDoc) && (
          <div className="lg:w-full">
            <UploadDoc key={documentDetails?.title} data={documentDetails} />
          </div>
        )}
      </div>
      {!props.filesUploaded && (
        <div className="flex gap-x-4 w-full mt-4 mr-auto">
          <button
            onClick={handleExtraDocsSubmission}
            className="rounded-lg text-sm bg-[#F6F8FA] border border-gray-300 px-6 py-2"
          >
            Back
          </button>
        </div>
      )}
    </div>
  );
}
export default DeclarationBox;
