import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewDashboard from "./pages/Dashboard/NewDashboard";
import LoginPageNew from "./pages/LoginPage/LoginPageNew";
import Admin from "./pages/Components/InstituteInformation/Admin";
const ProtectedRoute = ({ element, ...rest }: any) => {
  const token = localStorage.getItem("userDetails");

  return token ? element : <Navigate to="/login" />;
};
function App() {
  return (
    <div className=" font-poppins">
      <Routes>
        <Route path="/login" element={<LoginPageNew />} />
        <Route path="/verify-kyc" element={<LoginPageNew />} />
        <Route path="/admin" element={<Admin />} />
        <Route
          path="*"
          element={<ProtectedRoute element={<NewDashboard />} />}
        />
        {/* <Route path="/test" element={<div>upload dashboard</div>} /> */}
      </Routes>
      {/* <Dashboard /> */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
