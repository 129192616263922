import React, { useEffect, useState } from "react";
import InstituteInformation from "./InstituteInformation";
import Dashboard from "../../Dashboard/Dashboard";
import Navbar from "../../../components/Navbar";
import { useNavigate } from "react-router-dom";
function Admin({ setIsNav, setIsUser, IsUser, user, setUser }: any) {
  const [legalName, setLegalName] = useState("");
  const [isFormLoad, setIsFormLoad] = useState(false);
  const navigate = useNavigate();
  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    if (token) {
      localStorage.removeItem("urlToken");
      localStorage.setItem("userDetails", token);
      navigate("/");
    }
  }, [token, navigate]);

  return (
    <div className="h-screen bg-[#edf1f4]">
      {/* <Navbar
        setUser={setUser}
        setIsUser={setIsUser}
        information={user}
        isFormLoad={isFormLoad}
      /> */}
      {/* <InstituteInformation
        setIsNav={setIsNav}
        information={token}
        setLegalName={setLegalName}
        legalName={legalName}
      /> */}
    </div>
  );
}

export default Admin;
