import React from "react";
import doneUpload from "../assets/doneUploadTick.svg";

function DocumentUploaded({ setIsBackDrop }: any) {
  return (
    <div
      className="fixed font-poppins w-full flex justify-center items-center h-full top-0 left-0 z-50  overflow-hidden bg-black bg-opacity-50 "
      onClick={() => setIsBackDrop(false)}
    >
      <div className=" w-[350px] relative">
        <img
          src={doneUpload}
          alt="uploadTick"
          className="h-20 absolute -top-10 left-[140px] w-20 mx-auto "
        />
        <div className=" pt-20  bg-[#6687FF] text-center top-24 right-0  rounded-3xl overflow-hidden flex flex-col justify-center items-center">
          <div className="bg-[#6687FF] h-1/2">
            <p className="font-bold mb-4 tracking-wider text-white text-xl">
              DOCUMENT UPLOADED
            </p>
          </div>
          <div className="h-1/2 py-8 bg-[#F6F8FA]">
            <p className=" h-1/2 text-black font-bold text-xl">
              CONGRATULATIONS!
              <span className="px-8 inline-block h-1/2 w-full mt-2 font-light text-[#1B163B] text-sm">
                Your documents have been successfully uploaded. We will get back
                to you shortly after verification
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentUploaded;
