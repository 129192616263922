import { gql } from "@apollo/client";

export const UPLOAD_KYC_DOCUMENT = gql`
  mutation UploadKycDocument($file: String!, $typeName: String!) {
    uploadKycDocument(file: $file, typeName: $typeName)
  }
`;

export const VALIDATE_USER = gql`
  query ValidateUser($token: String!) {
    validateUser(token: $token) {
      email_id
      phone_number
      school_name
    }
  }
`;

export const SEND_OTP_KYC = gql`
  mutation SendOtpKyc($emailId: String!) {
    SendOtpKyc(emailId: $emailId)
  }
`;
export const VALIDATE_OTP_KYC = gql`
  mutation ValidateOtpKyc($email: String!, $otp: String!) {
    validateOtpKyc(email: $email, otp: $otp)
  }
`;

export const GET_KYC_DOCUMENTS = gql`
  query GetKycDocuments {
    getKycDocuments {
      _id
      aadhar
      affiliation
      authPan
      bankProof
      businessProof
      createdAt
      entityPan
      isSubmitted
      school
      updatedAt
      legalName
      businessCategory
      declarationDoc
      hasDeclaration
      websiteLink
    }
  }
`;

export const DELETE_KYC_DOCUMENT = gql`
  mutation DeleteKycDocument($typeName: String!) {
    deleteKycDocument(typeName: $typeName)
  }
`;

export const SUBMIT_KYC = gql`
  mutation SubmitKyc($hasDeclaration: Boolean!, $webSiteLink: String) {
    submitKyc(hasDeclaration: $hasDeclaration, webSiteLink: $webSiteLink)
  }
`;

export const UPDATE_DETAILS = gql`
  mutation UpdateDetails($details: detailsType!) {
    updateDetails(details: $details)
  }
`;
