import React from "react";

function IframeModal({ url, isOpen, onClose }: any) {

  const uniqueUrl = `${url}?v=${new Date().getTime()}`

  return (
    <>
      {isOpen && (
        <div className="min-h-screen  h-full w-full z-50 md:p-10 p-8 fixed top-0 left-0 bg-black bg-opacity-30">
          <div className="modal h-full relative">
            <button
              onClick={() => {
                onClose(false);
              }}
              className="absolute md:right-5 -right-6 -top-4 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={4}
                stroke="currentColor"
                className="w-6 h-6 text-white font-extrabold"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="modal-content h-full max-w-7xl mx-auto w-full">
              <iframe
                src={uniqueUrl} 
                title="PDF Viewer"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IframeModal;
