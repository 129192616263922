const documentData = [
  {
    title: "Business Proof",
    description:
      "Trust deed in case its a Trust, Partnership deed in case its apartnership entity",
    entityName: "businessProof",
    sampleFileUrl: [
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFile_BusinessProof.png",
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFile_BusinessProof2.jpg",
    ],
  },
  {
    title: "Entity PAN",
    description: "",
    entityName: "entityPan",
    sampleFileUrl: [
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFile_EntityPan.png",
    ],
  },
  {
    title: "Bank Proof",
    description: "Canceled Cheque",
    entityName: "bankProof",
    sampleFileUrl: [
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFile_BankProof.jpeg",
    ],
  },
  {
    title: "Affiliation Certificate / School Registration Certificate",
    description: "optional",
    entityName: "affiliation",
    sampleFileUrl: [
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFile_AffiliationCertificate.docx",
    ],
  },
  {
    title: "Authorised signatory PAN",
    description: "",
    entityName: "authPan",
    sampleFileUrl: [
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFile_AuthorizeSignPan.png",
    ],
  },
  {
    title: "Authorised signatory Aadhaar",
    description: "",
    entityName: "aadhar",
    sampleFileUrl: [
      "https://pg-kyc.s3.ap-south-1.amazonaws.com/sampleFiles/sampleFiles_AuthSIgnAadhar.jpg",
    ],
  },
];

export { documentData };
