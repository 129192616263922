import React from "react";

function TimeLineHorizontal(props: any) {
  return (
    <div className="flex items-center w-full">
      <div
        className={
          "h-1  w-full relative " +
          (props.infoStatus === false &&
          (props.type === "informationForm" || props.type === "KycForm")
            ? " bg-green-600"
            : " bg-gray-600")
        }
      >
        <div
          className={
            "w-5 h-5 bg-green-500 rounded-full absolute -top-2 left-1/2 -translate-x-[50%] " +
            (props.infoStatus === false &&
            (props.type === "informationForm" || props.type === "KycForm")
              ? " bg-green-600"
              : " bg-[#B8C0CD]")
          }
        ></div>
        <div className=" text-green-500  text-[8px] rounded-full absolute -top-6 left-1/2 -translate-x-[50%]">
          STEP 1
        </div>
        <div className=" text-green-500  w-20 text-[8px] text-center rounded-full absolute top-3 left-1/2 -translate-x-[50%]">
          Enter minimal details about your Institute
        </div>
      </div>
      <div
        className={
          "h-1  w-full relative " +
          (window.location.pathname === "/" && props.documentStatus
            ? "   bg-gray-300"
            : window.location.pathname === "/upload"
            ? " bg-green-600"
            : "   bg-green-600")
        }
      >
        <div
          className={
            "w-5 h-5 rounded-full absolute -top-2 left-1/2 -translate-x-[50%] " +
            (window.location.pathname === "/" && props.documentStatus
              ? "   bg-white border"
              : window.location.pathname === "/upload"
              ? " bg-green-600"
              : "   bg-green-600")
          }
        ></div>
        <div
          className={
            "  text-[8px] rounded-full absolute -top-6 left-1/2 -translate-x-[50%] " +
            (window.location.pathname === "/" && props.documentStatus
              ? "   text-[#717171]"
              : window.location.pathname === "/upload"
              ? " text-green-600"
              : "   text-green-600")
          }
        >
          STEP 2
        </div>
        <div
          className={
            "  text-[8px] w-20 text-center rounded-full absolute top-3 left-1/2 -translate-x-[50%] " +
            (window.location.pathname === "/" && props.documentStatus
              ? "   text-[#717171]"
              : window.location.pathname === "/upload"
              ? " text-green-600"
              : "   text-green-600")
          }
        >
          Upload documents of your Institute
        </div>
      </div>
      <div
        className={
          "h-1  w-full relative " +
          (props.isSubmitted === true ? " bg-green-600" : " bg-gray-300")
        }
      >
        <div
          className={
            "w-5 h-5  rounded-full absolute -top-2 left-1/2 -translate-x-[50%] " +
            (props.isSubmitted === true ? " bg-green-600" : " bg-white border")
          }
        ></div>
        <div
          className={
            "   text-[8px] rounded-full absolute -top-6 left-1/2 -translate-x-[50%] " +
            (props.isSubmitted === true
              ? "  text-green-600"
              : " text-[#717171]")
          }
        >
          STEP 3
        </div>
        <div
          className={
            "   text-[8px] w-20 text-center rounded-full absolute top-3 left-1/2 -translate-x-[50%] " +
            (props.isSubmitted === true
              ? "  text-green-600"
              : " text-[#717171]")
          }
        >
          Submit the details for verification
        </div>
      </div>
    </div>
  );
}

export default TimeLineHorizontal;
