import { useState } from "react";
import uploadLogo from "../../assets/uploadLogo.svg";
import uploadLogoDisabled from "../../assets/uploadLogoDisabled.svg";
import {
  DELETE_KYC_DOCUMENT,
  GET_KYC_DOCUMENTS,
  UPLOAD_KYC_DOCUMENT,
} from "../../Qurries";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { pdfjs } from "react-pdf";
import { VscFilePdf } from "react-icons/vsc";
import { RxCross1 } from "react-icons/rx";
import IframeModal from "../IframeModal";

function UploadDoc({ data,disableOn }: any) {
  const [fileNameData, setfilesNameData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: KYC_DOC, loading: loadingDoc } = useQuery(GET_KYC_DOCUMENTS);
  const [uploadKycDocument, { loading }] = useMutation(UPLOAD_KYC_DOCUMENT, {
    refetchQueries: [{ query: GET_KYC_DOCUMENTS }],
    errorPolicy: "ignore",
  });
  const [DELETE_DOCS, { loading: deleteDoc }] = useMutation(
    DELETE_KYC_DOCUMENT,
    {
      refetchQueries: [{ query: GET_KYC_DOCUMENTS }],
      errorPolicy: "ignore",
    }
  );

  // const pdfPreviewModal = (url: any) => {

  //   setIsModalOpen(true);
  // };

  const onSubmit = async (e: any, type: string) => {
    try {
      const data = await uploadKycDocument({
        variables: {
          file: e,
          typeName: type,
        },
      });
      if (!data?.data) {
        //clearDocs(type);
        // toast.error("Error in uploading document, Please check the file");
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  };
  const localNames: any = localStorage.getItem("FileNames");

  function handleFileSelect(event: any, type: string) {
    var selectedFile = event;
    const dataForm: any = {};
    if (selectedFile) {
      const jsonData: any = localStorage.getItem("FileNames");
      if (jsonData) {
        const data = JSON.parse(jsonData);
        data[type] = selectedFile.name;
        localStorage.setItem("FileNames", JSON.stringify(data));
        setfilesNameData(data);
      } else {
        dataForm[type] = selectedFile.name;
        localStorage.setItem("FileNames", JSON.stringify(dataForm));
        setfilesNameData(dataForm);
      }
      var reader = new FileReader();
      reader.onload = function (event) {
        var selectedFile: any = selectedFile;
        // Convert the file to Base64
        if (typeof event.target?.result === "string") {
          var selectedFileBase64 = event.target.result.split(",")[1];
          onSubmit(selectedFileBase64, type);
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  }
  const handleDeleteDocs = async (typeName: any) => {
    const res = await DELETE_DOCS({
      variables: {
        typeName: typeName,
      },
    });
    if (res?.data?.deleteKycDocument) {
      toast.success("Document(s) deleted successfully");
    }
  };

  const isPDFCorrupted = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target && event.target.result) {
          try {
            const blob = new Blob([event.target.result], {
              type: "application/pdf",
            });
            const url = URL.createObjectURL(blob);
            const pdfDocument = await pdfjs.getDocument({ url }).promise;
            resolve(false);
          } catch (error) {
            resolve(true);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };
  return (
    <>
      <IframeModal
        url={KYC_DOC?.getKycDocuments[data?.entityName]}
        isOpen={isModalOpen}
        onClose={setIsModalOpen}
      />
      <div
        className={`w-full relative  col-span-2   ${
          data.entityName === "aadhar" ? "col-span-2" : "md:col-span-1"
        } first:col-span-2 pt-2 border-2 rounded-md last:col-span-2 border-gray-400  border-dashed flex flex-col items-center justify-center`}
      >
        <h2 className="overflow-hidden flex gap-x-2 absolute left-10 -top-[9px] px-1 bg-[#F6F8FA] text-xs font-normal">
          {data?.title}{" "}
          {data?.description && (
            <>
              {" "}
              <span className="text-[#A9B2CF] sm:block hidden">(</span>
              <div className=" text-[#A9B2CF] xl:max-w-lg max-w-[10rem] sm:block hidden truncate">
                {data?.description}
              </div>
              <span className="text-[#A9B2CF] sm:block hidden">)</span>
            </>
          )}
        </h2>
        <div
          className={
            " md:min-h-fit min-h-16 flex flex-col " +
            (!KYC_DOC?.getKycDocuments[data?.entityName]
              ? " justify-center "
              : " justify-center my-2 min-h-0")
          }
        >
          {!KYC_DOC?.getKycDocuments[data?.entityName] ? (
            <>
              {loading || loadingDoc || deleteDoc ? (
                <i className="fa-solid animate-spin fa-spinner text-xl"></i>
              ) : (
                <>
                  <label htmlFor={data?.title}>
                    <img
                      src={disableOn?uploadLogoDisabled:uploadLogo}
                      alt="uploadImage"
                      className={`block m-auto ${!disableOn?'cursor-pointer':""} w-full h-8`}
                      onClick={() => {}}
                    />
                  </label>
                  <input
                    accept="image/*,.pdf"
                    disabled={disableOn}
                    onChange={async (e: any) => {
                      if (
                        e.target.files &&
                        e.target?.files[0]?.size < 10000000
                      ) {
                        if (e.target.files[0]?.type === "application/pdf") {
                          const isCorrupted = await isPDFCorrupted(
                            e.target.files[0]
                          );
                          if (isCorrupted) {
                            toast.error(
                              "Error in uploading document, Please check the file"
                            );
                          } else {
                            handleFileSelect(
                              e.target.files[0],
                              data?.entityName
                            );
                          }
                        } else {
                          handleFileSelect(e.target.files[0], data?.entityName);
                        }
                      } else {
                        toast.error("File size exceeds maximum limit of 10 MB");
                      }
                      e.target.value = "";
                    }}
                    type="file"
                    hidden
                    id={data?.title}
                  />
                  <p className={"text-[10px]"}>
                    <span className=" text-[#00AA11]">Upload</span> file
                    <span className="text-[#B8C0CD]"> (.pdf)</span>
                  </p>
                </>
              )}
            </>
          ) : (
            <div className="flex py-2 px-4 gap-x-2 text-[#505E8E] items-center text-xs bg-[#EAECF4] rounded-md">
              <div
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className={`   max-w-[10rem] truncate flex items-center cursor-pointer gap-2 ${
                  KYC_DOC?.getKycDocuments[data?.entityName] ? "" : "hidden"
                } `}
              >
                <VscFilePdf className="shrink-0" />
                <span className="truncate">
                  {JSON.parse(localNames)?.[data?.entityName] ||
                    data?.title + ".pdf"}
                </span>
              </div>
              {!KYC_DOC?.getKycDocuments?.isSubmitted && (
                <RxCross1
                  className={`${
                    KYC_DOC?.getKycDocuments[data?.entityName] ? "" : "hidden"
                  } cursor-pointer`}
                  onClick={() => {
                    handleDeleteDocs(data?.entityName);
                  }}
                />
              )}
            </div>
          )}
        </div>
        {data.entityName !== "affiliation" && (
          <div className="text-[10px] mr-2 absolute bottom-0 right-0">
            <a
              className=" ml-auto text-[#6687FF] cursor-pointer block"
              href={data.sampleFileUrl?.[0]}
              target="_blank"
              rel="noreferrer"
            >
              {data.entityName === "businessProof"
                ? "Sample File A"
                : "Sample File"}
            </a>
            {data.entityName === "businessProof" && (
              <a
                className=" ml-auto text-[#6687FF] cursor-pointer"
                href={data.sampleFileUrl?.[1]}
                target="_blank"
                rel="noreferrer"
              >
                Sample File B
              </a>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default UploadDoc;
