import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import SideBar from "../../../components/SideBar";
import TimeLine from "../../../components/TimeLine";
import "./InstituteInformation.css";
import { useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import {
  GET_KYC_DOCUMENTS,
  UPDATE_DETAILS,
  VALIDATE_USER,
} from "../../../Qurries";
import TimeLineHorizontal from "../../../components/TimeLineHorizontal";

export const hasNullValue = (obj: any) => {
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      key !== "declarationDoc" &&
      key !== "websiteLink" &&
      key !== "affiliation"&&
      obj[key] === null
    ) {
      return true;
    }
  }
  return false;
};
function InstituteInformation({
  userDetails,
  legalName,
  setLegalName,
  kyc,
  businessCategory,
  setBusinessCategory,
}: any) {
  const BusinessCategory: any = {
    INDIVIDUAL: "Individual",
    PROPRIETORSHIP: "Proprietorship",
    LLP: "LLP",
    PARTNERSHIP: "Partnership",
    PVTLTD: "Private Limited",
    PUBLTD: "Public Limited",
    OTHERS: "Society / Trust / Clubs / NGO / Association",
  };
  // const { isSubmitted } = useContext<any>(dashboardContext);
  const [checkBox, setcheckBox] = useState<boolean>(false);
  const [backDrop, setBackDrop] = useState<boolean>(false);
  const dropDown = useRef<any>();

  const navigate = useNavigate();
  const checkBoxRef = useRef(null);

  const [updateDetails] = useMutation(UPDATE_DETAILS);

  useEffect(() => {
    const dropDownHandler = (e: any) => {
      if (!dropDown.current.contains(e.target)) {
        setBackDrop(false);
      }
    };
    document.addEventListener("click", dropDownHandler);

    return () => {
      document.removeEventListener("click", dropDownHandler);
    };
  });

  const continueBtnStatus =
    kyc?.getKycDocuments?.isSubmitted === true
      ? !kyc?.getKycDocuments?.isSubmitted
      : !(checkBox && legalName);
  useEffect(() => {
    if (kyc?.getKycDocuments?.isSubmitted === true) {
      setcheckBox(true);
    } else {
      setcheckBox(false);
    }
  }, [kyc?.getKycDocuments?.isSubmitted]);

  // if (kyc?.getKycDocuments?.isSubmitted === true)
  //   return <Navigate to="/upload" />;

  return (
    <div className="">
      {kyc?.getKycDocuments?.isSubmitted === true && (
        <p className="text-[#00AA11] text-sm">
          Your documents have been successfully uploaded. We will get back to
          you shortly after verification.
        </p>
      )}

      <div className="mt-4 bg-[#F6F8FA] rounded-lg px-8 py-6 relative overflow-hidden min-h-[36rem]">
        {/* <div className="mb-4">

          <h1 className="right-4 text-[#6687FF] font-poppins font-semibold text-lg">
            Complete Your KYC
          </h1>
          <div className="h-10 w-0  ml-4 border border-solid border-slate-500 bg-slate-300"></div>
          <div className="relative h-0 ml-4 w-4 mb-[39px] border border-solid border-slate-500 bg-slate-300">
            <div className="absolute right-[-2px] top-[-4px] w-2 h-2 border-solid border-t-2 border-r-2 border-slate-500 transform rotate-45"></div>
            <h2 className="absolute top-[-10.5px] left-8 w-max text-[14px] font-medium">
              Institute information
            </h2>
          </div>
        </div> */}

        <div className="mb-4 flex flex-col">
          <div className="w-full">
            <h1 className="right-4 text-[#6687FF] font-poppins font-semibold text-lg">
              Complete Your KYC
            </h1>
            <div className="h-10 w-0  ml-4 border border-solid border-slate-500 bg-slate-300"></div>
            <div className="relative h-0 ml-4 w-4 mb-[39px] border border-solid border-slate-500 bg-slate-300">
              <div className="absolute right-[-2px] top-[-4px] w-2 h-2 border-solid border-t-2 border-r-2 border-slate-500 transform rotate-45"></div>
              <h2 className="absolute top-[-10.5px] left-8 w-max text-[14px] font-medium">
                Institute information
              </h2>
            </div>
          </div>
          <div className=" lg:hidden block w-full mt-4 mb-8">
            <TimeLineHorizontal
              type={"informationForm"}
              infoStatus={hasNullValue(userDetails)}
              documentStatus={hasNullValue(kyc?.getKycDocuments)}
              isSubmitted={kyc?.getKycDocuments?.isSubmitted}
            />
          </div>
        </div>
        <div className="rounded-lg w-full mb-4 min-w-full">
          <div className="w-full lg:pl-11 pl-4 flex justify-between">
            <form className="lg:w-3/4 w-full grid grid-cols-2">
              <div className="inputForm py-2 w-full col-span-2">
                <label
                  className={
                    "absolute top-0 left-10 bg-[#f6f8fa] px-1 text-xs font-normal " +
                    (userDetails?.school_name
                      ? " text-[#B8C0CD] "
                      : " text-[#1B163B]")
                  }
                >
                  Institute Name
                </label>
                <input
                  disabled={userDetails?.school_name}
                  className={
                    "px-4 py-3 bg-transparent text-xs w-full border-2 rounded-md border-solid border-gray-300 outline-none " +
                    (userDetails?.school_name
                      ? " text-[#717171] "
                      : " text-[#B8C0CD]")
                  }
                  placeholder="enter name"
                  type="text"
                  value={userDetails?.school_name}
                ></input>
              </div>
              <div className="inputForm lg:col-span-1 col-span-2 w-full">
                <label
                  className={
                    "absolute top-0 left-10 bg-[#f6f8fa] px-1 text-xs font-normal " +
                    (userDetails?.phone_number
                      ? " text-[#B8C0CD] "
                      : " text-[#1B163B]")
                  }
                >
                  Mobile Number
                </label>
                <input
                  disabled={userDetails?.phone_number}
                  className={
                    "px-4 py-3 bg-transparent text-xs w-full border-2 rounded-md border-solid border-gray-300 outline-none " +
                    (userDetails?.phone_number
                      ? " text-[#717171] "
                      : " text-[#B8C0CD]")
                  }
                  placeholder="enter number"
                  type="numeric"
                  value={userDetails?.phone_number}
                ></input>
              </div>
              <div className="inputForm lg:col-span-1 col-span-2 w-full">
                <label
                  className={
                    "absolute top-0 left-10 bg-[#f6f8fa] px-1 text-xs font-normal " +
                    (userDetails?.email_id
                      ? " text-[#B8C0CD] "
                      : " text-[#1B163B]")
                  }
                >
                  Email ID
                </label>
                <input
                  disabled={userDetails?.email_id}
                  className={
                    "px-4 py-3 bg-transparent text-xs w-full border-2 rounded-md border-solid border-gray-300 outline-none " +
                    (userDetails?.email_id
                      ? " text-[#717171] "
                      : " text-[#B8C0CD]")
                  }
                  placeholder="enter ID"
                  type="text"
                  value={userDetails?.email_id}
                ></input>
              </div>
              <div className="inputForm w-full col-span-2">
                <label
                  className={
                    " absolute top-0  left-10 bg-[#f6f8fa] px-1 text-xs font-normal " +
                    (kyc?.getKycDocuments?.isSubmitted === true
                      ? " text-[#B8C0CD]"
                      : " text-[#1B163B]")
                  }
                >
                  Legal Name
                </label>
                <input
                  required
                  className={
                    "px-4 py-3 bg-transparent text-xs w-full border-2 rounded-md border-solid border-gray-300 outline-none " +
                    (legalName ? " text-[#717171] " : " text-[#B8C0CD]")
                  }
                  placeholder="enter name"
                  type="text"
                  disabled={kyc?.getKycDocuments?.isSubmitted === true}
                  defaultValue={legalName}
                  onChange={(e) => {
                    setLegalName(e.target.value);
                  }}
                ></input>
              </div>
              <div className="inputForm lg:col-span-1 col-span-2 w-full">
                <div
                  className={
                    "absolute top-0 left-10 bg-[#f6f8fa] px-1 text-xs font-normal z-[200]" +
                    (kyc?.getKycDocuments?.isSubmitted === true
                      ? " text-[#B8C0CD]"
                      : " text-[#1B163B]")
                  }
                >
                  Business Category
                </div>

                <div
                  ref={dropDown}
                  onClick={() => {
                    setBackDrop(!backDrop);
                  }}
                  className={
                    "relative px-4 py-3 bg-transparent text-[#717171] text-xs w-full border-2 rounded-md border-solid border-gray-300 outline-none flex"
                  }
                >
                  {businessCategory}
                  {!kyc?.getKycDocuments?.isSubmitted === true && (
                    <div
                      className={`absolute lg:max-h-[10rem] max-h-[8.5rem] overflow-y-auto top-[3rem] left-0 w-full z-10 bg-[#f6f8fa] text-[#717171] text-xs  border-2 rounded-md border-solid border-gray-300 outline-none select-shadow ring-1 ring-black ring-opacity-5 focus:outline-none ${
                        backDrop ? "block" : "hidden"
                      }`}
                    >
                      <ul className="">
                        {Object.keys(BusinessCategory).map(
                          (category: string) => (
                            <li
                              className={`p-2 text-[#1B163B] cursor-default hover:bg-[#EEF1F6] `}
                              key={category}
                              onClick={() =>
                                setBusinessCategory(BusinessCategory[category])
                              }
                            >
                              {BusinessCategory[category]}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  {!kyc?.getKycDocuments?.isSubmitted === true && (
                    <div
                      className="block ml-auto"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {backDrop ? (
                        <MdOutlineKeyboardArrowUp
                          onClick={() => setBackDrop(!backDrop)}
                          className="w-6 h-4"
                        />
                      ) : (
                        <MdOutlineKeyboardArrowDown
                          onClick={() => setBackDrop(!backDrop)}
                          className="w-6 h-4"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="inputForm lg:col-span-1 col-span-2 w-full">
                <label
                  className={
                    "absolute top-0 left-10 bg-[#f6f8fa] px-1 text-xs font-normal " +
                    (userDetails?.school_name
                      ? " text-[#B8C0CD] "
                      : " text-[#1B163B]")
                  }
                >
                  Business Type
                </label>
                <input
                  disabled
                  className="px-4 py-3 bg-transparent text-[#717171]  text-xs w-full border-2 rounded-md border-solid border-gray-300 outline-none"
                  value="Education"
                  type="text"
                ></input>
              </div>
            </form>
            <div className="lg:block hidden">
              <TimeLine
                type={"informationForm"}
                infoStatus={hasNullValue(userDetails)}
                documentStatus={hasNullValue(kyc?.getKycDocuments)}
                isSubmitted={kyc?.getKycDocuments?.isSubmitted}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center mb-4 lg:pl-[55px] pl-[26px] mt-5 ">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 accent-[#6687FF]"
            id="option1"
            name="options"
            disabled={kyc?.getKycDocuments?.isSubmitted === true}
            checked={checkBox}
            ref={checkBoxRef}
            onChange={(e) => {
              if (e.target.checked) {
                setcheckBox(true);
              } else {
                setcheckBox(false);
              }
            }}
          ></input>

          <label
            htmlFor="option1"
            className="cursor-pointer text-sm text-[#1B163B] items-center ml-2"
          >
            I agree to the
            <Link
              to="https://www.edviron.com/terms-&-conditions.html"
              target="_blank"
              className="ml-1 text-[#6687FF] hover:underline underline-offset-2"
            >
              terms and conditions
            </Link>
          </label>
        </div>
      </div>

      <div className="flex justify-end items-center mt-4">
        <button
          disabled={continueBtnStatus}
          onClick={async (e) => {
            e.preventDefault();
            if (!kyc?.getKycDocuments?.isSubmitted) {
              //some issues pending
              await updateDetails({
                variables: {
                  details: {
                    legalName: legalName,
                    businessCategory: Object.keys(BusinessCategory).find(
                      (key) => BusinessCategory[key] === businessCategory
                    ),
                  },
                },
              });
            }

            navigate("/upload");
          }}
          className="px-6 py-2 text-sm text-white disabled:text-[#F6F8FA] rounded-md bg-[#6687FF] disabled:bg-[#B8C0CD] disabled:border border-[#B8C0CD]"
        >
          Continue
        </button>
      </div>
    </div>
  );
}
export default InstituteInformation;
