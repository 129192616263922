import React, { useEffect, useState } from "react";
import LogoSvg from "./assets/svg/voice_control_ofo1.svg";
import EdvironLogo from "./assets/svg/logo.svg";
import {
  GET_KYC_DOCUMENTS,
  SEND_OTP_KYC,
  VALIDATE_OTP_KYC,
} from "../../Qurries";
import { useMutation, useQuery } from "@apollo/client";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function LoginPageNew() {
  const [otpVisible, setotpVisible] = useState(false);
  const [otp, setotp] = useState("");
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [otp_expire_time, set_otp_expire_time] = useState(180);
  const [resent_opt_time, set_resent_otp_time] = useState(60);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const jwtToken = urlParams.get("token") || "";
  const [send_otp_kyc, { loading }] = useMutation(SEND_OTP_KYC, {
    errorPolicy: "ignore",
  });

  const [validate_otp_kyc] = useMutation(VALIDATE_OTP_KYC, {
    errorPolicy: "ignore",
  });

  useEffect(() => {
    try {
      if (jwtToken !== "" && jwtToken !== localStorage.getItem("urlToken")) {
        localStorage.removeItem("urlToken");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("FileNames");
      }
      if (jwtToken) {
        const base64Url = jwtToken.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const user_email = JSON.parse(window.atob(base64))?.email_id;
        setEmail(user_email);
        // localStorage.setItem("urlEmail", user_email);
        localStorage.setItem("urlToken", jwtToken);
        if (localStorage.getItem("urlToken")) {
          navigate(`/verify-kyc?token=${jwtToken}`);
        }
      }
    } catch (err) {
      toast.error("Invalid Url");
    }
  }, [jwtToken]);

  // const { data } = useQuery(GET_KYC_DOCUMENTS);
  // if (data?.getKycDocuments?.isSubmitted === true) {
  //   setIsSubmitted(true);
  // }
  const otpHandler = async () => {
    try {
      const res = await send_otp_kyc({
        variables: {
          emailId: email,
        },
      });
      if (res.data && res.data.SendOtpKyc) {
        setOtpSent(true);
        set_otp_expire_time(180);
        set_resent_otp_time(60);
        toast.success("OTP has been sent again");
      } else {
        toast.error(res.data);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const submitHandler = async () => {
    const res = await validate_otp_kyc({
      variables: {
        email,
        otp: otp,
      },
    });
    if (res?.data?.validateOtpKyc) {
      if (jwtToken) {
        const base64Url = jwtToken.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const user_email = JSON.parse(window.atob(base64))?.email_id;
        if (user_email !== email && localStorage.getItem("urlToken")) {
          localStorage.removeItem("urlToken");
          localStorage.removeItem("FileNames");
        }
      }
      if (!jwtToken && localStorage.getItem("urlToken")) {
        localStorage.removeItem("urlToken");
        localStorage.removeItem("FileNames");
      }
      setVerified(true);
      //setUser(res.data.validateOtpKyc);
      localStorage.setItem("userDetails", res.data.validateOtpKyc);
      //setIsUser(true);
      navigate("/");
    }
  };

  // if (data.getUserQuery)
  useEffect(() => {
    let timer: any;
    if (otpSent) {
      if (otp_expire_time > 0) {
        timer = setInterval(() => {
          set_otp_expire_time(otp_expire_time - 1);
        }, 1000);
      }
      if (otp_expire_time <= 0) {
        window.location.reload();
      }
    }
    return () => {
      clearInterval(timer);
    };
  }, [otp_expire_time, otpSent]);
  useEffect(() => {
    let timer: any;
    if (otpSent) {
      if (resent_opt_time > 0) {
        timer = setInterval(() => {
          set_resent_otp_time(resent_opt_time - 1);
        }, 1000);
      }
    }
    return () => {
      clearInterval(timer);
    };
  }, [resent_opt_time, otpSent]);

  if (localStorage.getItem("userDetails")) return <Navigate to="/"></Navigate>;

  return (
    <div className="w-full h-screen grid gap-x-8 lg:!grid-cols-5 grid-cols-1">
      <div className=" gap-y-4 col-span-2 w-full flex flex-col text-center  md:p-20 px-10 py-20 bg-[#eceefa] xl:rounded-r-[20px] justify-center items-center">
        <div className=" ">
          <img
            src={EdvironLogo}
            className="ml-auto w-48 h-full"
            alt="Edviron log"
          />
        </div>
        <div className="mt-20 w-full">
          <h3 className="font-bold text-3xl">Sign in to your account</h3>
        </div>

        <form
          // onSubmit={async (e) => {
          //   e.preventDefault();

          //   // if (res.data === true) {
          //   //   localStorage.setItem("token", res.data);
          //   //   await refetch().then((data) => {
          //   //     if (data) set_user(data);
          //   //   });
          //   //   navigate("/");
          //   // }
          // }}
          className="md:max-w-[25rem] w-full space-y-4 mt-12"
        >
          <div className="flex flex-col gap-y-2">
            <label
              className="capitalize  text-base font-bold text-left"
              htmlFor="emailId"
            >
              Email
            </label>
            <div className="bg-white w-full border border-gray-200 rounded-lg overflow-hidden">
              <input
                defaultValue={email}
                type="email"
                className="w-full p-3 px-4 focus:outline-none"
                id="emailId"
                placeholder="enter email id"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          {otpSent && (
            <>
              <div className="flex flex-col gap-y-2">
                <label
                  className="capitalize font-semibold text-base text-left"
                  htmlFor="otp"
                >
                  OTP
                </label>
                <div className="bg-white flex items-center pr-3 w-full border border-gray-200 rounded-lg overflow-hidden">
                  <input
                    type={otpVisible ? "text" : "otp"}
                    className="w-full p-3 px-4 focus:outline-none"
                    id="otp"
                    placeholder="enter OTP"
                    onChange={(e) => setotp(e.target.value)}
                    required
                  />
                  {/* {otpVisible ? (
                    <i
                      onClick={() => setotpVisible(false)}
                      className="fa-solid fa-eye-slash cursor-pointer"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setotpVisible(true)}
                      className="fa-solid fa-eye cursor-pointer"
                    ></i>
                  )} */}
                </div>
              </div>
              <div className="flex text-[12px]  font-light items-center justify-between">
                <p>
                  OTP expires in {`${Math.floor(otp_expire_time / 60)} `}
                  {otp_expire_time - Math.floor(otp_expire_time / 60) * 60 >
                    0 &&
                    `: ${
                      otp_expire_time - Math.floor(otp_expire_time / 60) * 60 <
                      10
                        ? "0"
                        : ""
                    }${
                      otp_expire_time - Math.floor(otp_expire_time / 60) * 60
                    } `}
                  mins
                </p>

                <p className="my-2">
                  Didn’t Receive ?
                  <button
                    disabled={otp_expire_time > 120}
                    onClick={async (e) => {
                      e.preventDefault();
                      otpHandler();
                    }}
                    className="text-[#6F6AF8] ml-2 cursor-pointer disabled:text-gray-500"
                  >
                    Resend {resent_opt_time > 0 && `(${resent_opt_time}s)`}
                  </button>
                </p>
              </div>
            </>
          )}
          <div>
            {otpSent && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  submitHandler();
                }}
                className="bg-[#d6daf3] w-full px-4 py-3 mt-4 rounded-lg"
              >
                {loading ? (
                  <i className="fa-solid animate-spin fa-spinner"></i>
                ) : (
                  "Sign In"
                )}
              </button>
            )}
            {!otpSent && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  otpHandler();
                }}
                className="bg-[#d6daf3] w-full px-4 py-3 mt-4 rounded-lg"
              >
                {loading ? (
                  <i className="fa-solid animate-spin fa-spinner"></i>
                ) : (
                  "Send OTP"
                )}
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="col-span-3 w-full hidden lg:flex items-center px-32 justify-center bg-white">
        <img src={LogoSvg} className="w-full h-full" alt="" />
      </div>
    </div>
  );
}

export default LoginPageNew;
