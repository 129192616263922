function TimeLine(props: any) {
  return (
    // <div className="w-1/4 flex flex-col items-center text-[#717171]">
    //   <h1 className=" mt-2">Sign in to your E-dash</h1>
    //   <div className=" flex justify-center items-center ">
    //     <div className=" h-64 w-1 bg-green-600 relative">
    //       <div
    //         className={
    //           "w-20 h-1  absolute bottom-0 " +
    //           (submit === true ? " bg-green-600" : "bg-[#B8C0CD]")
    //         }
    //       >
    //         <p className="ml-[100px] w-max text-[#1B163B] mt-[-10px] ">
    //           Step 3<br />
    //           <span className="text-xs block w-min">
    //             submit the details for verification
    //           </span>
    //         </p>
    //       </div>
    //       <div
    //         className={
    //           "w-4 h-4 bg-[#B8C0CD] transform rotate-45 absolute bottom-[-6px] left-[75px] " +
    //           (submit === true ? " bg-green-600" : "bg-[#B8C0CD]")
    //         }
    //       ></div>
    //       <div
    //         className={`w-20 h-1 ${
    //           backgroundColorKyc !== "" ? backgroundColorKyc : "bg-[#B8C0CD]"
    //         } absolute bottom-[120px] right-0 `}
    //       >
    //         <p className="ml-[-72px] mt-[-10px] w-max text-[#1B163B]">
    //           Step 2<br />
    //           <span className="text-xs block w-min">
    //             Upload Basic Documents of your trust/society
    //           </span>
    //         </p>
    //       </div>
    //       <div
    //         className={`w-4 h-4  ${
    //           backgroundColorKyc !== "" ? backgroundColorKyc : "bg-[#B8C0CD]"
    //         } transform rotate-45 absolute bottom-[114px] right-[75px]`}
    //       ></div>
    //       <div
    //         className={`w-20 h-1 ${
    //           backgroundColorInformation !== ""
    //             ? backgroundColorInformation
    //             : "bg-[#B8C0CD]"
    //         }  top-20 absolute`}
    //       >
    //         <p className=" ml-[98px] mt-[-10px] w-max text-[#1B163B]">
    //           Step 1<br />
    //           <span className="text-xs block w-min">
    //             Enter Minimal Details about your trust/society
    //           </span>
    //         </p>
    //       </div>
    //       <div
    //         className={`w-4 h-4  ${
    //           backgroundColorInformation !== ""
    //             ? backgroundColorInformation
    //             : "bg-[#B8C0CD]"
    //         } transform rotate-45 absolute left-[75px] top-[74px]`}
    //       ></div>
    //     </div>
    //   </div>
    // </div>
    <div className="flex w-96 py-2 justify-center">
      <div
        className={
          "relative h-20 w-1.5  " +
          (props.infoStatus === false &&
          (props.type === "informationForm" || props.type === "KycForm")
            ? " bg-green-600"
            : " bg-[#B8C0CD]")
        }
      >
        <div
          className={
            "absolute bottom-0 h-1.5 w-28  " +
            (props.infoStatus === false &&
            (props.type === "informationForm" || props.type === "KycForm")
              ? " bg-green-600"
              : " bg-[#B8C0CD]")
          }
        ></div>
        <div
          className={
            "absolute -bottom-1.5 -right-28 h-5 w-5 rotate-45 transform " +
            (props.infoStatus === false &&
            (props.type === "informationForm" || props.type === "KycForm")
              ? " bg-green-600"
              : " bg-[#B8C0CD]")
          }
        ></div>
        <div className="absolute -bottom-[6rem] -right-[10rem] w-10">
          <p className="w-full text-sm">Step 1</p>
          <p className="text-[10px]">
            Enter minimal details about your Institute
          </p>
        </div>
      </div>
      <div
        className={
          "relative -ml-[6px] mt-20 h-20 w-1.5  " +
          (window.location.pathname === "/" && props.documentStatus
            ? "   bg-[#B8C0CD]"
            : window.location.pathname === "/upload"
            ? " bg-green-600"
            : "   bg-green-600")
        }
      >
        <div
          className={
            "absolute -left-28 bottom-0 h-1.5 w-28 transform " +
            (window.location.pathname === "/" && props.documentStatus
              ? "   bg-[#B8C0CD]"
              : window.location.pathname === "/upload"
              ? " bg-green-600"
              : "   bg-green-600")
          }
        ></div>
        <div
          className={
            "absolute -bottom-1.5 -left-28 h-5 w-5 rotate-45 transform " +
            (window.location.pathname === "/" && props.documentStatus
              ? "   bg-[#B8C0CD]"
              : window.location.pathname === "/upload"
              ? " bg-green-600"
              : "   bg-green-600")
          }
        ></div>
        <div className="absolute -bottom-16 -left-[163px] w-12">
          <p className="w-full text-sm">Step 2</p>
          <p className="text-[10px]">Upload documents of your Institute</p>
        </div>
      </div>
      <div
        className={
          "relative -ml-[6px] mt-[10rem] h-20 w-1.5  " +
          (props.documentStatus === false ? " bg-green-600" : " bg-[#B8C0CD]")
        }
      >
        <div
          className={
            "absolute bottom-0 h-1.5 w-28 " +
            (props.isSubmitted === true ? " bg-green-600" : " bg-[#B8C0CD]")
          }
        ></div>
        <div
          className={
            "absolute -bottom-1.5 -right-28 h-5 w-5 rotate-45 transform " +
            (props.isSubmitted === true ? " bg-green-600" : " bg-[#B8C0CD]")
          }
        ></div>
        <div className="absolute -bottom-[5rem] -right-[166px] w-12">
          <p className="w-full text-sm">Step 3</p>
          <p className="text-[10px]">Submit the details for verification</p>
        </div>
      </div>
    </div>
  );
}
export default TimeLine;
