import React from "react";

function ConfirmationBox({
  setOpen,
  funtion,
  confirmationText,
  ButtonText,
  ButtonBackground,
  CborderColor,
}: any) {
  return (
    <div className="w-11/12 m-auto text-center">
      <div className="mt-10">Are you sure you want to {confirmationText}?</div>

      <div className="mt-10 mb-10 grid grid-cols-2 gap-4">
        <button
          onClick={() => setOpen(false)}
          className={
            "py-1 px-4 rounded-md bg-[#F8FAFB]  border-2  outline-none " +
            (CborderColor && CborderColor
              ? CborderColor
              : " border-red-200 text-red-400")
          }
        >
          Cancel
        </button>

        <button
          onClick={async () => {
            funtion();
            setOpen(false);
          }}
          className={
            `py-2 text-white px-10 rounded-lg  ` +
            (ButtonBackground && ButtonBackground
              ? ButtonBackground
              : " bg-red-400")
          }
        >
          {ButtonText}
        </button>
      </div>
    </div>
  );
}

export default ConfirmationBox;
